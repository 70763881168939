export default {
  home: 'Home',
  my_tricks: 'My Tricks',
  contact: 'Contact',
  tandc: 'T&C',
  tandc_long: 'Terms & Conditions',
  privacy_policy: 'Privacy Policy',
  login: 'Login',
  register: 'Register',
  my_dogs: 'My Dogs',
  my_orders: 'My Orders',
  tricks: 'Tricks',
  trick_library: 'Trick library',
  how_it_works: 'How it works',
  core_programme: 'Core programme',
  bomus_curses: 'Bonus courses',
  bundles: 'Bundles',
  dashboard: 'Dashboard',
  feedback: 'Feedback',
  users: 'Users',
  orders: 'Orders',
  dogs: 'Dogs',
  leaderboard: 'Leaderboard',
  hall_of_fame: 'Hall of fame',
  tricks_more: 'More Tricks',
  categories: 'Categories',
  admin: 'Admin',
  settings: 'Settings',
  shopping_cart: 'Shopping Cart',
  profile: 'Profile',
  logout: 'Logout',
  view_cart: 'View Cart',
  join: 'Join',
  summer: 'Summer School',
  about: 'About',
  admin_dogs: 'Dogs',
  admin_users: 'Users',
  admin_discounts: 'Discounts',
  admin_videos: 'Videos',
  admin_award: 'Awards',
  admin_tricks: 'Tricks',
  admin_categories: 'Categories',
  admin_topics: 'Topics',
  admin_feedback: 'Feedback',
  admin_bundles: 'Bundles',
  admin_badges: 'Badges',
  admin_seasons: 'Seasons',
  admin_levels: 'Levels',
  visit_us: 'Visit us',
  view_our: 'View our',
  facebook: 'Facebook',
  instagram: 'Instagram'
};
