import React from 'react';
import {
  FETCH_LIST_SUCCESS,
  FETCH_LIST_ITEM_SUCCESS,
  SET_TOKEN,
  SET_USER,
  ADD_CART_ITEM,
  SET_DISCOUNT_CODE,
  SET_LEVELS,
  SET_TOPICS,
  LOGOUT,
  UPDATE_CART,
  SET_PURCHASE,
  SUCCESSFUL_PURCHASE,
  SET_BADGES
} from '../actions';
import { notification } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

type Props = {
  type: string;
  payload: any;
};

const INITIAL_STATE: any = {};

export default (state = INITIAL_STATE, { type, payload }: Props) => {
  switch (type) {
    case FETCH_LIST_SUCCESS:
      return { ...state, list: payload.list };
    case FETCH_LIST_ITEM_SUCCESS:
      return { ...state, listItem: payload.item };
    case SET_TOKEN:
      window.localStorage.setItem('token', payload.token);
      return state;
    case SET_LEVELS:
      return { ...state, levels: payload.levels };
    case SET_BADGES:
      return { ...state, badges: payload.badges };
    case SET_TOPICS:
      return { ...state, topics: payload.topics };
    case SET_USER:
      if (payload.redirectPath) {
        window.location.href = payload.redirectPath;
        window.localStorage.removeItem('pathname');
      }

      let cart;
      // Load cart
      if (window.localStorage.getItem('cart')) {
        cart = window.localStorage.getItem('cart');

        if (cart && cart !== 'undefined') {
          cart = JSON.parse(cart);
        } else {
          cart = [];
        }
      }

      let purchase;
      // Load purchase
      if (window.localStorage.getItem('purchase')) {
        purchase = window.localStorage.getItem('purchase');

        if (purchase && purchase !== 'undefined') {
          purchase = JSON.parse(purchase);
        } else {
          purchase = {};
        }
      }

      return { ...state, user: payload.user, cart: cart, purchase: purchase };
    case ADD_CART_ITEM:
      if (!state.cart) {
        state.cart = [];
      }

      let array = [...state.cart];
      let exists = false;

      // Validate cart
      array.forEach((cartItem, i) => {
        if (
          cartItem.dog.id === payload.trick.dog.id &&
          cartItem.id === payload.trick.id &&
          payload.trick.type === cartItem.type
        ) {
          exists = true;
        }
      });

      if (exists) {
        notification.open({
          message: 'Item Already Exists In The Cart',
          icon: <SmileOutlined style={{ color: '#108ee9' }} />
        });
      } else {
        array.push(payload.trick);

        window.localStorage.setItem('cart', JSON.stringify(array));

        notification.open({
          message: 'Item Added To Cart',
          icon: <SmileOutlined style={{ color: '#108ee9' }} />
        });
      }

      return { ...state, cart: array };

    case SET_DISCOUNT_CODE:
      if (!state.discountCode) {
        state.discountCode = { code: '', amount: 0 };
      }

      return { ...state, discountCode: payload.discountCode };
    case UPDATE_CART:
      window.localStorage.setItem('cart', JSON.stringify(payload));

      return { ...state, cart: payload };
    case SUCCESSFUL_PURCHASE:
      window.localStorage.removeItem('cart');
      window.localStorage.removeItem('purchase');
      return { ...state, cart: [], purchase: {} };
    case SET_PURCHASE:
      window.localStorage.setItem('purchase', JSON.stringify(payload));

      return { ...state, purchase: payload };
    case LOGOUT:
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('cart');
      window.localStorage.removeItem('purchase');

      return { ...state, user: payload.user };
    default:
      return state;
  }
};
