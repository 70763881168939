import React from 'react';
import { Layout } from 'antd';
import SidebarMenu from '@components/menu/SidebarMenu';

const SideBar = ({ collapsed, callback }) => {
  return (
    <Layout.Sider
      className="sidebar"
      breakpoint={'lg'}
      theme="light"
      collapsedWidth={0}
      trigger={null}
      collapsed={collapsed}
      style={{ position: 'fixed' }}
    >
      <SidebarMenu mode="vertical" callback={callback} />
    </Layout.Sider>
  );
};

export default SideBar;
