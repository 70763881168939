export default {
  price: 'Price',
  total: 'Total',
  gbp: '£',
  remove: 'Remove',
  checkout: 'Checkout',
  trick: 'Trick',
  dog: 'Dog',
  apply: 'Apply',
  discount_code: 'Discount Code',
  your_cart: 'Your Cart',
  discount: 'Discount'
};
