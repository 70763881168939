import { Dispatch } from 'redux';
import { requests } from '@utils/api';
import { notification } from 'antd';
import React from 'react';
import { history } from './../store';
import { ErrorHandler } from '@components/error/Error';

export const FETCH_LIST_REQUEST: string = 'fetch_list_request';
export const FETCH_LIST_SUCCESS: string = 'fetch_list_success';
export const FETCH_LIST_ITEM_SUCCESS: string = 'fetch_list_item_success';
export const FETCH_LIST_FAILURE: string = 'fetch_list_failure';

export type DispatchFetchList = () => (dispatch: Dispatch) => Promise<void>;

export const fetchList: DispatchFetchList = () => async dispatch =>
  new Promise(
    (resolve, reject): void => {
      dispatch({
        type: FETCH_LIST_REQUEST,
        payload: { resolve, reject }
      });
    }
  );

export const SET_AUTH: string = 'set_auth';
export const SET_UNAUTH: string = 'set_unauth';
export const SET_TOKEN: string = 'set_token';
export const SET_PURCHASE: string = 'set_purchase';
export const SET_USER: string = 'set_user';
export const LOGOUT: string = 'logout';
export const UPDATE_CART: string = 'update_cart';
export const SET_DISCOUNT_CODE: string = 'set_discount_code';
export const SET_LEVELS: string = 'set_levels';
export const SET_BADGES: string = 'set_badges';
export const SET_TOPICS: string = 'set_topics';
export const SUCCESSFUL_PURCHASE: string = 'successful_purchase';

export const capitalizeFirstLetter = (stringVal: string) => {
  return stringVal.charAt(0).toUpperCase() + stringVal.slice(1);
};

export const login = (loginForm: any, redirectPath?: string) => (dispatch: any) => {
  requests
    .post('/login', { username: loginForm.email, password: loginForm.password })
    .then(data => {
      dispatch({
        type: SET_TOKEN,
        payload: { token: data.token }
      });
      dispatch(loadUser(redirectPath));
    })
    .catch(err => {
      // todo translations
      notification.error({
        message: 'Error',
        description: 'Invalid login or password'
      });
    });
};

export const join = (form: any, hasCompletedCourses: boolean) => (dispatch: any) => {
  let values = {
    firstName: form.firstName,
    lastName: form.lastName,
    password: form.password,
    email: form.email
  };

  requests
    .post('/register', values)
    .then(data => {
      // todo translations
      notification.success({
        message: 'Success',
        description: 'Welcome to Trickstars!'
      });
      dispatch(login({ email: values.email, password: values.password }, '/dogs'));
    })
    .catch(err => {
      ErrorHandler(err);
    });
};

export const addDog = (form: any) => (dispatch: any) => {
  let values = {
    name: form.name,
    breed: form.breed,
    profileImage: form.profileImage,
    hasCompletedCourses: form.hasCompletedCourses,
    birthDate: form.birthDate.format('Y-MM-DD')
  };

  requests
    .post('/dogs', values)
    .then(data => {
      // todo translations
      notification.success({
        message: 'Success',
        description: 'Dog has been added'
      });

      // todo REDIRECT
      history.push('/dogs');
    })
    .catch(err => {
      ErrorHandler(err);
    });
};

export const updateDog = (form: any, id: number) => (dispatch: any) => {
  let values = {
    name: form.name,
    breed: form.breed,
    profileImage: form.profileImage,
    hasCompletedCourses: form.hasCompletedCourses || 0,
    birthDate: form.birthDate.format('Y-MM-DD')
  };

  requests
    .put('/dogs/' + id, values)
    .then(data => {
      // todo translations
      notification.success({
        message: 'Success',
        description: 'Dog has been updated'
      });

      // todo REDIRECT
      history.push('/dogs/' + id + '/view');
    })
    .catch(err => {
      ErrorHandler(err);
    });
};

export const logout = () => (dispatch: any) => {
  dispatch({
    type: LOGOUT,
    payload: { user: null }
  });

  setTimeout(() => {
    history.push('/');
  }, 200);
};

export const loadUser = (redirectPath?: string) => (dispatch: any) => {
  if (window.localStorage.getItem('token')) {
    requests
      .get('/me')
      .then(data => {
        dispatch({
          type: SET_USER,
          payload: { user: data, redirectPath: redirectPath }
        });
      })
      .catch(err => {
        console.log(err);
      });
  } else {
    return false;
  }
};

export const loadLevels = () => (dispatch: any) => {
  requests
    .get('/v1/levels')
    .then(data => {
      dispatch({
        type: SET_LEVELS,
        payload: { levels: data }
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const loadBadges = () => (dispatch: any) => {
  requests
    .get('/v1/badges')
    .then(data => {
      dispatch({
        type: SET_BADGES,
        payload: { badges: data }
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const loadTopics = () => (dispatch: any) => {
  requests
    .get('/v1/topics')
    .then(data => {
      dispatch({
        type: SET_TOPICS,
        payload: { topics: data }
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const loadDogs = () => async (dispatch: any) => {
  let list = await requests
    .get('/dogs')
    .then(data => {
      console.log(data);
      dispatch({
        type: FETCH_LIST_SUCCESS,
        payload: { list: data }
      });

      return data;
    })
    .catch(err => {
      console.log(err);
    });

  return list;
};

export const loadDog = (id: number) => async (dispatch: any) => {
  let list = await requests
    .get(`/dogs/` + id)
    .then(data => {
      dispatch({
        type: FETCH_LIST_ITEM_SUCCESS,
        payload: { item: data }
      });

      return data;
    })
    .catch(err => {
      console.log(err);
    });

  return list;
};

export const ADD_CART_ITEM: string = 'add_cart_item';
export const REMOVE_CART_ITEM: string = 'add_cart_item';
export const UPDATE_CART_ITEM: string = 'update_cart_item';
