import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import store, { history } from '../redux/store';
import AppLayout from '@components/layout/Layout';

const Home = React.lazy(() => import('../screens/home/Home'));
const Tricks = React.lazy(() => import('../screens/tricks/Tricks'));
const Bundles = React.lazy(() => import('../screens/bundles/Bundles'));
const CoreProgramme = React.lazy(() => import('../screens/bundles/CoreProgramme'));
const BonusCourses = React.lazy(() => import('../screens/bundles/BonusCourses'));
const Bundle = React.lazy(() => import('../screens/bundles/bundle/Bundle'));
const MyTricks = React.lazy(() => import('../screens/tricks/MyTricks'));
const TrickCategorties = React.lazy(() => import('../screens/tricks/TrickCategories'));
const Trick = React.lazy(() => import('../screens/tricks/trick/Trick'));
const MyTrick = React.lazy(() => import('../screens/tricks/trick/MyTrick'));
const FreeShaping = React.lazy(() => import('../screens/static/FreeShaping'));
const Leaderboard = React.lazy(() => import('../screens/leaderboard/Leaderboard'));
const LastMonthLeaders = React.lazy(() => import('../screens/leaderboard/LastMonthLeaders'));
const OverallLeaders = React.lazy(() => import('../screens/leaderboard/OverallLeaders'));
const Login = React.lazy(() => import('../screens/login/LoginPage'));
const Logout = React.lazy(() => import('../screens/logout/Logout'));
const Register = React.lazy(() => import('../screens/register/Register'));
const ForgotPassword = React.lazy(() => import('../screens/forgotpassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../screens/resetpassword/ResetPassword'));
const Contact = React.lazy(() => import('../screens/contact/Contact'));
const Tandc = React.lazy(() => import('../screens/tandc/Tandc'));
const Privacy = React.lazy(() => import('../screens/static/Privacy'));
const Faq = React.lazy(() => import('../screens/faq/Faq'));
const HowItWorks = React.lazy(() => import('../screens/static/How'));
const AboutMe = React.lazy(() => import('../screens/static/AboutMe'));
const Cart = React.lazy(() => import('../screens/cart/Cart'));
const Checkout = React.lazy(() => import('../screens/checkout/Checkout'));
const Seasons = React.lazy(() => import('../screens/seasons/Seasons'));
const SeasonBoard = React.lazy(() => import('../screens/leaderboard/SeasonBoard'));
const CheckoutSuccess = React.lazy(() => import('../screens/checkout/CheckoutSuccess'));
const About = React.lazy(() => import('../screens/about/About'));
const Summer = React.lazy(() => import('../screens/summer/Summer'));
const Join = React.lazy(() => import('../screens/join/Join'));
const Dogs = React.lazy(() => import('../screens/dogs/Dogs'));
const Dog = React.lazy(() => import('../screens/dogs/Dog'));
const Award = React.lazy(() => import('../screens/award/Award'));
const DogAddEdit = React.lazy(() => import('../screens/dogs/DogAddEdit'));
const AdminDogs = React.lazy(() => import('../screens/admin/dogs/Dogs'));
const AdminUsers = React.lazy(() => import('../screens/admin/users/Users'));
const AdminUserView = React.lazy(() => import('../screens/admin/users/UserView'));
const AdminUserGift = React.lazy(() => import('../screens/admin/users/UserGift'));
const AdminDogAddEdit = React.lazy(() => import('../screens/admin/dogs/DogAddEdit'));
const AdminDiscounts = React.lazy(() => import('../screens/admin/discounts/Discounts'));
const AdminDiscountsAddEdit = React.lazy(() =>
  import('../screens/admin/discounts/DiscountAddEdit')
);
const AdminFeedback = React.lazy(() => import('../screens/admin/feedback/Feedback'));
const AdminAwards = React.lazy(() => import('../screens/admin/award/Award'));
const AdminAward = React.lazy(() => import('../screens/admin/award/Awards'));
const AdminFeedbackTrick = React.lazy(() => import('../screens/admin/feedback/FeedbackTrick'));
const AdminLevels = React.lazy(() => import('../screens/admin/levels/Levels'));
const AdminLevelAddEdit = React.lazy(() => import('../screens/admin/levels/LevelAddEdit'));
const AdminBadges = React.lazy(() => import('../screens/admin/badges/Badges'));
const AdminBadgeAddEdit = React.lazy(() => import('../screens/admin/badges/BadgeAddEdit'));
const AdminBundles = React.lazy(() => import('../screens/admin/bundles/Bundles'));
const AdminBundleAddEdit = React.lazy(() => import('../screens/admin/bundles/BundleAddEdit'));
const AdminVideos = React.lazy(() => import('../screens/admin/videos/Videos'));
const AdminVideosView = React.lazy(() => import('../screens/admin/videos/Video'));
const AdminVideosAddEdit = React.lazy(() => import('../screens/admin/videos/VideoAddEdit'));
const AdminTricks = React.lazy(() => import('../screens/admin/tricks/Tricks'));
const AdminTrickAddEdit = React.lazy(() => import('../screens/admin/tricks/TrickAddEdit'));
const AdminTopics = React.lazy(() => import('../screens/admin/topics/Topic'));
const AdminTopicAddEdit = React.lazy(() => import('../screens/admin/topics/TopicAddEdit'));
const AdminSeasons = React.lazy(() => import('../screens/admin/seasons/Seasons'));
const AdminSeasonAddEdit = React.lazy(() => import('../screens/admin/seasons/SeasonAddEdit'));

const publicPaths = [
  { exact: true, path: '/', component: Home },
  { exact: true, path: '/leaderboard', component: Leaderboard },
  { exact: true, path: '/leaderboard/month', component: LastMonthLeaders },
  { exact: true, path: '/leaderboard/overall', component: OverallLeaders },
  { exact: true, path: '/bundles', component: Bundles },
  { exact: true, path: '/core-programme', component: CoreProgramme },
  { exact: true, path: '/bonus-courses', component: BonusCourses },
  { exact: true, path: '/bundles/:id', component: Bundle },
  { exact: true, path: '/tricks', component: Tricks },
  { exact: true, path: '/tricks/:id', component: Trick },
  { exact: true, path: '/tricks/my/:id', component: MyTrick },
  { exact: true, path: '/categories/:id', component: Tricks },
  { exact: true, path: '/categories', component: TrickCategorties },
  { exact: true, path: '/my-tricks', component: MyTricks },
  { exact: true, path: '/dogs', component: Dogs },
  { exact: true, path: '/dogs/add', component: DogAddEdit },
  { exact: true, path: '/dogs/:id/view', component: Dog },
  { exact: true, path: '/dogs/:id/edit', component: DogAddEdit },
  { exact: true, path: '/login', component: Login },
  { exact: true, path: '/logout', component: Logout },
  { exact: true, path: '/join', component: Join },
  { exact: true, path: '/register', component: Register },
  { exact: true, path: '/forgot-password', component: ForgotPassword },
  { exact: true, path: '/reset-password/:token', component: ResetPassword },
  { exact: true, path: '/contact', component: Contact },
  { exact: true, path: '/faq', component: Faq },
  { exact: true, path: '/how-it-works', component: HowItWorks },
  { exact: true, path: '/free-shaping', component: FreeShaping },
  { exact: true, path: '/award-delivery/:token', component: Award },
  { exact: true, path: '/seasons', component: Seasons },
  { exact: true, path: '/seasons/:id', component: SeasonBoard },
  { exact: true, path: '/privacy', component: Privacy },
  { exact: true, path: '/tandc', component: Tandc },
  { exact: true, path: '/basket', component: Tandc },
  { exact: true, path: '/cart', component: Cart },
  { exact: true, path: '/cart/checkout', component: Checkout },
  { exact: true, path: '/cart/checkout/success', component: CheckoutSuccess },
  { exact: true, path: '/summer-school', component: Summer },
  { exact: true, path: '/about', component: About },
  { exact: true, path: '/about-me', component: AboutMe },
  { exact: true, path: '/admin/discounts', component: AdminDiscounts },
  { exact: true, path: '/admin/discounts/add', component: AdminDiscountsAddEdit },
  { exact: true, path: '/admin/discounts/:id/edit', component: AdminDiscountsAddEdit },
  { exact: true, path: '/admin/users', component: AdminUsers },
  { exact: true, path: '/admin/users/:id/view', component: AdminUserView },
  { exact: true, path: '/admin/users/:id/gift/:type', component: AdminUserGift },
  { exact: true, path: '/admin/dogs', component: AdminDogs },
  { exact: true, path: '/admin/dogs/:id/edit', component: AdminDogAddEdit },
  { exact: true, path: '/admin/videos', component: AdminVideos },
  { exact: true, path: '/admin/videos/:id', component: AdminVideosView },
  { exact: true, path: '/admin/video/add', component: AdminVideosAddEdit },
  { exact: true, path: '/admin/topics', component: AdminTopics },
  { exact: true, path: '/admin/topics/add', component: AdminTopicAddEdit },
  { exact: true, path: '/admin/topics/:id/edit', component: AdminTopicAddEdit },
  { exact: true, path: '/admin/badges', component: AdminBadges },
  { exact: true, path: '/admin/badges/add', component: AdminBadgeAddEdit },
  { exact: true, path: '/admin/badges/:id/edit', component: AdminBadgeAddEdit },
  { exact: true, path: '/admin/levels', component: AdminLevels },
  { exact: true, path: '/admin/levels/add', component: AdminLevelAddEdit },
  { exact: true, path: '/admin/levels/:id/edit', component: AdminLevelAddEdit },
  { exact: true, path: '/admin/seasons', component: AdminSeasons },
  { exact: true, path: '/admin/seasons/add', component: AdminSeasonAddEdit },
  { exact: true, path: '/admin/seasons/:id/edit', component: AdminSeasonAddEdit },
  { exact: true, path: '/admin/bundles', component: AdminBundles },
  { exact: true, path: '/admin/bundles/add', component: AdminBundleAddEdit },
  { exact: true, path: '/admin/bundles/:id/edit', component: AdminBundleAddEdit },
  { exact: true, path: '/admin/tricks', component: AdminTricks },
  { exact: true, path: '/admin/tricks/add', component: AdminTrickAddEdit },
  { exact: true, path: '/admin/tricks/:id/edit', component: AdminTrickAddEdit },
  { exact: true, path: '/admin/award', component: AdminAwards },
  { exact: true, path: '/admin/award/:id', component: AdminAward },
  { exact: true, path: '/admin/feedback', component: AdminFeedback },
  { exact: true, path: '/admin/feedback/:id', component: AdminFeedbackTrick }
];

const publicRoutes = publicPaths.map(({ path, ...props }) => (
  <Route key={path} path={path} {...props} />
));

export default () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <AppLayout>
          <Suspense fallback={<div />}>
            {publicRoutes}
            {/* <Route component={NotFound} /> */}
          </Suspense>
        </AppLayout>
      </Switch>
    </ConnectedRouter>
  </Provider>
);
