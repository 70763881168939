import React from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';

const SidebarMenu = ({ mode, callback }) => {
  const { t } = useTranslation('topMenu');
  const user = useSelector((state: IReducerStates) => state.global.user);

  const menuItems = [
    { key: 'how_it_works', route: '/how-it-works', requireRoles: ['ANONYMOUS', 'ROLE_USER'] },
    { key: 'core_programme', route: '/core-programme', requireRoles: ['ANONYMOUS', 'ROLE_USER'] },
    { key: 'bomus_curses', route: '/bonus-courses', requireRoles: ['ANONYMOUS', 'ROLE_USER'] },
    { key: 'trick_library', route: '/tricks', requireRoles: ['ANONYMOUS', 'ROLE_USER'] },
    { key: 'hall_of_fame', route: '/leaderboard', requireRoles: ['ROLE_USER'] },
    { key: 'join', route: '/join', requireRoles: ['ANONYMOUS'] },
    { key: 'login', route: '/login', requireRoles: ['ANONYMOUS'] },
    { key: 'my_dogs', route: '/dogs', requireRoles: ['ROLE_USER'] },
    {
      key: 'admin',
      requireRoles: ['ROLE_ADMIN'],
      children: [
        { key: 'admin_feedback', route: '/admin/feedback' },
        { key: 'admin_award', route: '/admin/award' },
        { key: 'admin_tricks', route: '/admin/tricks' },
        { key: 'admin_bundles', route: '/admin/bundles' },
        { key: 'admin_discounts', route: '/admin/discounts' },
        { key: 'admin_dogs', route: '/admin/dogs' },
        { key: 'admin_users', route: '/admin/users' },
        { key: 'admin_levels', route: '/admin/levels' },
        { key: 'admin_topics', route: '/admin/topics' },
        { key: 'admin_badges', route: '/admin/badges' },
        { key: 'admin_seasons', route: '/admin/seasons' }
      ]
    }
  ];

  const getUserRole = () => {
    return user ? user.roles : ['ANONYMOUS'];
  };

  const checkRoles = (requireRoles: Array<string>) => {
    let grandAccess = false;

    requireRoles.forEach(role => {
      if (getUserRole().indexOf(role) !== -1) {
        grandAccess = true;
      }
    });

    return grandAccess;
  };

  return (
    <Menu mode={mode} style={{ background: 'none', height: '100px', lineHeight: '100px' }}>
      <Menu.Item key="close" onClick={callback}>
        <LeftOutlined className="white" />
      </Menu.Item>
      {/* {menuItems.map(
        item =>
          checkRoles(item.requireRoles) && (
            <Menu.Item key={item.key} onClick={callback}>
              <Link to={item.route}>{t(item.key)}</Link>
            </Menu.Item>
          )
      )} */}

      {menuItems.map(item =>
        !item.hasOwnProperty('children')
          ? checkRoles(item.requireRoles) && (
              <Menu.Item key={item.key} onClick={callback}>
                <Link to={item.route}>{t(item.key)}</Link>
              </Menu.Item>
            )
          : checkRoles(item.requireRoles) && (
              <Menu.ItemGroup key={item.key} title={t(item.key)}>
                {item.children.map(child => (
                  <Menu.Item key={child.key} onClick={callback}>
                    <Link to={child.route}>{t(child.key)}</Link>
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            )
      )}

      {checkRoles(['ROLE_USER']) && (
        <Menu.Item key="logout" onClick={callback}>
          <Link to="/logout">{t('logout')}</Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default SidebarMenu;
