import React, { useEffect } from 'react';
import { Menu, Badge } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useTranslation } from 'react-i18next';
import {
  SettingOutlined,
  ShoppingCartOutlined,
  ShoppingFilled,
  SettingFilled
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loadUser, loadLevels, loadBadges, loadTopics } from '@redux/actions';

const TopMenu = ({ mode }) => {
  const { t } = useTranslation('topMenu');
  const user = useSelector((state: IReducerStates) => state.global.user);
  const cart = useSelector((state: IReducerStates) => state.global.cart);

  const dispatch = useDispatch();
  let cartItems = cart ? cart.length : 0;

  useEffect(() => {
    (async () => {
      //   await setFetching(true);

      try {
        await dispatch(loadUser());
        await dispatch(loadLevels());
        await dispatch(loadBadges());
        await dispatch(loadTopics());
      } catch (error) {
        // console.log(error)
      }

      //   setFetching(false);
    })();
  }, [dispatch]);

  const menuItems = [
    // { key: 'home', route: '/', requireRoles: ['ANONYMOUS', 'ROLE_USER'] },
    // { key: 'about', route: '/about', requireRoles: ['ANONYMOUS', 'ROLE_USER'] },
    { key: 'how_it_works', route: '/how-it-works', requireRoles: ['ANONYMOUS', 'ROLE_USER'] },
    { key: 'core_programme', route: '/core-programme', requireRoles: ['ANONYMOUS', 'ROLE_USER'] },
    { key: 'bomus_curses', route: '/bonus-courses', requireRoles: ['ANONYMOUS', 'ROLE_USER'] },
    { key: 'trick_library', route: '/tricks', requireRoles: ['ANONYMOUS', 'ROLE_USER'] },
    { key: 'hall_of_fame', route: '/leaderboard', requireRoles: ['ROLE_USER'] },
    { key: 'join', route: '/join', requireRoles: ['ANONYMOUS'] },
    { key: 'login', route: '/login', requireRoles: ['ANONYMOUS'] },
    { key: 'my_dogs', route: '/dogs', requireRoles: ['ROLE_USER'] },
    {
      key: 'admin',
      requireRoles: ['ROLE_ADMIN'],
      children: [
        { key: 'admin_feedback', route: '/admin/feedback' },
        { key: 'admin_award', route: '/admin/award' },
        { key: 'admin_tricks', route: '/admin/tricks' },
        { key: 'admin_bundles', route: '/admin/bundles' },
        { key: 'admin_discounts', route: '/admin/discounts' },
        { key: 'admin_dogs', route: '/admin/dogs' },
        { key: 'admin_users', route: '/admin/users' },
        { key: 'admin_levels', route: '/admin/levels' },
        { key: 'admin_topics', route: '/admin/topics' },
        { key: 'admin_badges', route: '/admin/badges' },
        { key: 'admin_seasons', route: '/admin/seasons' }
      ]
    }
  ];

  const getUserRole = () => {
    // return ['ANONYMOUS'];
    //return ['ROLE_USER'];
    //return ['ROLE_USER', 'ROLE_ADMIN'];
    return user ? user.roles : ['ANONYMOUS'];
  };

  const checkRoles = (requireRoles: Array<string>) => {
    let grandAccess = false;

    requireRoles.forEach(role => {
      if (getUserRole().indexOf(role) !== -1) {
        grandAccess = true;
      }
    });

    return grandAccess;
  };

  return (
    <Menu
      className="main-menu"
      mode={mode}
      style={{ background: 'none', height: '100px', lineHeight: '100px' }}
    >
      {menuItems.map(item =>
        !item.hasOwnProperty('children')
          ? checkRoles(item.requireRoles) && (
              <Menu.Item key={item.key}>
                <Link to={item.route}>{t(item.key)}</Link>
              </Menu.Item>
            )
          : checkRoles(item.requireRoles) && (
              <SubMenu key={item.key} title={t(item.key)}>
                <Menu.ItemGroup title={t(item.key)}>
                  {item.children.map(child => (
                    <Menu.Item key={child.key}>
                      <Link to={child.route}>{t(child.key)}</Link>
                    </Menu.Item>
                  ))}
                </Menu.ItemGroup>
              </SubMenu>
            )
      )}

      {mode == 'horizontal' && checkRoles(['ROLE_USER']) && (
        <SubMenu key="settings" style={{ float: 'right' }} icon={<SettingFilled />}>
          <Menu.ItemGroup title={t('settings')}>
            <Menu.Item key="logout">
              <Link to="/logout">{t('logout')}</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
      )}
      {mode == 'horizontal' && (
        <Menu.Item key="cart" style={{ float: 'right' }}>
          <Link to="/cart">
            <Badge count={cartItems}>
              {/* <ShoppingCartOutlined /> */}
              <ShoppingFilled />
            </Badge>
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default TopMenu;
