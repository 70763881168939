import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { parse } from 'url';

const instance = axios.create({
  baseURL: 'https://trickstarsuni.com/api/',
  timeout: 15000
});

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (window.localStorage.getItem('token')) {
    config.headers = { Authorization: `Bearer ${window.localStorage.getItem('token')}` };
  }

  return config;
};

instance.interceptors.request.use(onRequest);
instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.config.url.indexOf('login') === -1
    ) {
      let redirectCount = window.localStorage.getItem('redirectCount')
        ? parseInt(window.localStorage.getItem('redirectCount'))
        : 1;

      if (window.localStorage.getItem('redirectCount')) {
        redirectCount += 1;
      }

      if (redirectCount < 2) {
        window.localStorage.setItem('redirectCount', redirectCount.toString());
        window.localStorage.setItem('pathname', window.location.pathname);
      } else {
        window.localStorage.removeItem('pathname');
        window.localStorage.removeItem('redirectCount');
      }

      window.localStorage.removeItem('token');
      document.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

const responseBody = (response: AxiosResponse) => response.data;

export const requests = {
  get: (url: string) => instance.get(url).then(responseBody),
  post: (url: string, body: {}) => instance.post(url, body).then(responseBody),
  postFile: (url: string, body: {}) =>
    instance
      .post(url, body, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 3000000
      })
      .then(responseBody),
  put: (url: string, body: {}) => instance.put(url, body).then(responseBody),
  delete: (url: string) => instance.delete(url).then(responseBody)
};
