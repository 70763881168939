import React, { useState } from 'react';
import { Layout, Badge, Menu, Row, Col } from 'antd';
import TopMenu from '@components/menu/Menu';
import { Content, Header } from 'antd/lib/layout/layout';
import SideBar from '@components/sidebar/Sidebar';
import { MenuOutlined, ShoppingFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const AppLayout = ({ children }) => {
  const { t } = useTranslation('topMenu');
  const [openSidebar, setOpenSidebar] = useState(true);
  const cart = useSelector((state: IReducerStates) => state.global.cart);
  let cartItems = cart ? cart.length : 0;

  const toggleMenu = () => {
    setOpenSidebar(!openSidebar);
  };

  return (
    <Layout className="classroom">
      <SideBar collapsed={openSidebar} callback={toggleMenu} />
      <Header
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          padding: '0 0',
          height: '100px',
          background: 'url("/purple-bg-tile.jpg")',
          backgroundSize: '30px'
        }}
      >
        <a href="/">
          <picture>
            <source type="image/webp" srcSet="/logo.webp" />
            <source type="image/png" srcSet="/logo.png" />
            <img
              src="/logo.png"
              style={{ height: '80px', margin: '10px 30px 10px 30px', float: 'left' }}
            />
          </picture>
        </a>
        <MenuOutlined className="mobile-menu-button" onClick={() => setOpenSidebar(!openSidebar)} />
        <TopMenu mode="horizontal" />

        <Menu
          style={{
            background: 'none',
            height: '100px',
            lineHeight: '100px',
            width: '100px'
          }}
          className="mobile-menu-cart"
          mode={'horizontal'}
        >
          <Menu.Item key="cart">
            <Link to="/cart">
              <Badge count={cartItems}>
                <ShoppingFilled />
              </Badge>
            </Link>
          </Menu.Item>
        </Menu>
      </Header>
      <Content>
        <div className="main-bg">{children}</div>

        <div className="clearfix" />

        <div className="footer-bg">
          <Row justify="center" align="top">
            <Col xs={24} sm={18} md={18} lg={18} xl={18} style={{ padding: '20px' }}>
              <div style={{ display: 'block', position: 'relative' }}>
                <Row justify="center" align="top" className="pt30">
                  <Col span={8}>
                    <picture>
                      <source type="image/webp" srcSet="/logo.webp" />
                      <source type="image/png" srcSet="/logo.png" />
                      <img src="/logo.png" className="footer-logo" />
                    </picture>
                  </Col>
                  <Col span={8} style={{ paddingLeft: '15px', paddingRight: '5px' }}>
                    <p className="pt30 white-text">{t('visit_us')}:</p>
                    <a href="https://www.facebook.com/TrickstarsUniversity">{t('facebook')}</a>
                    <div className="clearfix" />
                    <a href="https://www.instagram.com/trickstars_uni/">{t('instagram')}</a>
                  </Col>
                  <Col span={8} style={{ paddingLeft: '15px', paddingRight: '5px' }}>
                    <p className="pt30 white-text">{t('view_our')}:</p>
                    <a href="/tandc">{t('tandc_long')}</a>
                    <div className="clearfix" />
                    <a href="/privacy">{t('privacy_policy')}</a>
                    <div className="clearfix" />
                    <a href="/contact">{t('contact')}</a>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div className="clearfix" />
          <Row justify="center" align="top" style={{ padding: '10px 20px ' }} className="pt20">
            <p className="white-text">
              Ⓒ {moment().format('Y')} Trickstars University®. All rights Reserved.
            </p>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default AppLayout;
