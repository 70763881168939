import React from 'react';
import { notification } from 'antd';
import { capitalizeFirstLetter } from '@redux/actions';

export const ErrorHandler = err => {
  const errors = err.response.data;

  if (errors.status === 500) {
    return notification.error({
      message: 'Error',
      description: <code>{errors.detail}</code>
    });
  }

  if (typeof errors === 'object') {
    Object.keys(errors).forEach((key, index) => {
      notification.error({
        message: 'Error',
        description: (
          <code>
            {capitalizeFirstLetter(key)}: {errors[key]}
          </code>
        )
      });
    });
  } else {
    notification.error({
      message: 'Error',
      description: <code>Unknown Error</code>
    });
  }
};
