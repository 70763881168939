export default {
  or: 'or',
  register_now: 'register now!',
  join_now: 'Join Us Now!',
  forgot_password: 'Forgot password',
  remember_me: 'Remember me',
  login: 'Login',
  password: 'Password',
  email: 'Email',
  email_validation: 'Please input your email!',
  password_validation: 'Please input your password!',
};


