export default {
  loading: 'Loading...',
  title: 'Example',
  welcome: 'Welcome to Trickstars University!',
  homepage_1_welcome_to_trickstars:
    'The home of online dog trick training. The university contains over 150 tricks to work through, from puppy tricks and foundations to the more advanced!',
  homepage_2_the_university_contains: '',
  learn_more: 'Learn More',
  what_else: 'Individual coaching and support.',
  join_today: 'Join Today',
  cas_title: 'Individual coaching and support.',
  homepage_1_what_else:
    'You will get walked through each trick step by step with individual support and coaching at every stage. You can follow our Core programme or create your own programme by choosing your own tricks from the trick library.',
  homepage_1_what_else3:
    'You will get walked through each trick step by step with individual support and coaching at every stage.',
  homepage_2_what_else:
    'You can choose your own tricks or pick one of our trick bundles to give you some inspiration.',
  awards_and_badges: 'Earn points, badges and rosettes!',
  homepage_1_awards_and_badges:
    'Every trick you complete, gains you points towards titles! When your dog gains a title they will have a badge added to their profile and they will receive a rosette in the post. Top point scorers will be featured on our seasonal leader board.',
  homepage_2_awards_and_badges:
    'The University’s summer school also contains a lot of agility skills short courses, check them out!',
  homepage_3_awards_and_badges: 'Come and join our community!',
  f2f_title: 'Face to face training days',
  new_tricks: 'New tricks',
  new_releases: 'New Releases',
  f2f_title_text:
    'Fancy coming to a Trickstars workshop? There are a lot of training days happening in different areas this year!',
  f2f_title_text2: 'Fancy coming to a Trickstars workshop?',
  f2f_title_text3: 'There are a lot of training days happening in different areas this year!',
  free_shaping_view: 'View our Free Shaping Video!'
};
