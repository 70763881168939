export default {
  back: 'Back',
  gbp: '£',
  add_to_cart: 'Add To Cart',
  rank: 'Rank',
  points: 'Points',
  total_points: 'Total Points',
  about: 'About',
  join: 'Join',
  search: 'Search',
  join_now: 'Join Now',
  login: 'Login',
  create: 'Create',
  update: 'Update',
  edit: 'Edit',
  view: 'View',
  remove: 'Remove',
  create_new: 'Add New',
  completed: 'Completed',
  in_progress: 'In Progress',
  is_hidden: 'Is Hidden',
  your_dogs: 'Your Dogs',
  add_a_dog: 'Add a Dog',
  dog: 'Dog',
  dogs: 'Dogs',
  users: 'Users',
  bundle: 'Bundle',
  purchased: 'Purchased',
  discounts: 'Discounts',
  amount: 'Amount',
  expire_date: 'Expire at',
  used_count: 'Times used',
  amount_percentage: 'Amount (%)',
  code: 'Code',
  gift_trick: 'Gift Trick',
  gift_bundle: 'Gift Bundle',
  trick: 'Trick',
  id: 'Id',
  video: 'Video',
  add_video: 'Upload Video',
  add_a_category: 'Add new Category',
  add_a_bundle: 'Add new Bundle',
  add_a_badge: 'Add new Badge',
  add_a_discount: 'Add new Discount',
  add_a_level: 'Add new Level',
  add_a_topic: 'Add new Topic',
  edit_a_category: 'Edit Category',
  edit_a_bundle: 'Edit Bundle',
  edit_a_badge: 'Edit Badge',
  edit_a_discount: 'Edit Discount',
  edit_a_level: 'Edit Level',
  edit_a_topic: 'Edit Topic',
  edit_a_dog: 'Edit Dog',
  points_target: 'Points Target',
  profile: 'Profile',
  guide: 'Guide',
  download_guide: 'Download PDF',
  download: 'Download',
  user: 'User',
  tricks: 'Tricks',
  size: 'Size',
  prerequisite_tricks: 'Prerequisite Tricks',
  next_tricks: 'Next Tricks (for email promo)',
  categories: 'Categories',
  select_trick: 'Select trick',
  select_bundle: 'Select bundle',
  topic: 'Topic',
  topics: 'Topics',
  bundles: 'Bundles',
  levels: 'Levels',
  level_validation: 'Level/s are required',
  field_validation: 'Field is required',
  badges: 'Badges',
  badge: 'Badge',
  leader_badge: 'Leader Badge',
  start_date: 'Start Date',
  end_date: 'End Date',
  no_active_seasons: 'No active seasons',
  view_seasons: 'View all seasons',
  name: 'Name',
  breed: 'Breed',
  description: 'Description',
  category: 'Category',
  feedback: 'Feedback',
  reset: 'Reset',
  release_at: 'Release At',
  hide_at: 'Hide At',
  active_at: 'Active At',
  inactive_at: 'Inactive At',
  add_cover: 'Add Cover',
  forgot_password: 'Forgot password?',
  forgot_password_additional_info: 'Please fill your account email to get password reset link.',
  reset_password: 'Forgot password?',
  reset_password_additional_info: 'Please enter your new password and confirm it bellow.',
  search_for_trick: 'Search for a trick',
  date_of_birth: 'Date Of Birth',
  please_type_dog_name: 'Please type your dog name',
  fill_please: 'Fill please',
  please_select_breed: 'Please select breed',
  upload_cover_picture: 'Upload Cover Picture',
  name_validation: 'Name is required',
  description_validation: 'Description is required',
  breed_validation: 'Breed is required',
  dob_validation: 'Date of Birth is required',
  add_profile_image: 'Add Profile Image',
  add_guide_pdf: 'Add Guide PDF',
  search_by_title: 'Search by title',
  this_month_leaders: 'This Month Leaders',
  this_season_leaders: 'Spring 2022 leaderboard',
  first_name: 'Forename',
  first_name_validation: 'Forename is required',
  last_name: 'Surname',
  last_name_validation: 'Surname is required',
  email: 'Email',
  email_validation: 'Email is required',
  password: 'Password',
  password_validation: 'Password is required',
  repeat_password: 'Repeat Password',
  repeat_password_validation: 'Field is required',
  field_required_validation: 'Field is required',
  by_registering_i_accept_t_and_c: 'By joining you accept our Terms and Conditions',
  register: 'Register',
  please_select_dog: 'Select a dog',
  add_a_trick: 'Add a Trick',
  title: 'Title',
  price: 'Price',
  submitted: 'Submitted',
  congratulations: 'Congratulations',
  profile_picture: 'Profile Picture (press to edit)',
  add_picture: 'Picture (press to edit)',
  thumb: 'Thumb',
  active: 'Active',
  inactive: 'Inactive',
  pending_reply: 'Pending Reply',
  empty: 'Empty',
  replied: 'Replied',
  learn_more: 'Learn More',
  login_go: 'Go to login',
  status: 'Status',
  owner: 'Owner',
  read_more: 'Read More',
  comment: 'Comment',
  seasons: 'Seasons',
  remove_comment: 'Remove Comment',
  comments_count: 'comments',
  added_comment: 'added a comment',
  expires_at: 'Expires At',
  mark_as_completed: 'Complete',
  mark_as_sent: 'Mark as Sent',
  mark_as_seen: 'Mark as Seen',
  add_comment: 'Post',
  expire_months: 'Expire after (months)',
  expires_after: 'Expires after',
  months: 'months',
  select_level: 'Select Level',
  select_difficulty: 'Select Difficulty',
  select_topic: 'Select Topic',
  join_today: 'Join Today',
  owner_email: 'Owner Email',
  season_leaderboard: 'Season Leaderboard',
  all_seasons: 'All Seasons',
  last_month_leaders: 'Last Month Leaders',
  overall_leaders: 'Highest Point Scorers',
  level: 'Level',
  core_programme: 'Core Programme',
  bonus_courses: 'Bonus Courses',
  address: 'Address',
  award_delivery_information: 'Delivery Information',
  size_limit: 'Size Restrictions',
  size_limit_info:
    'Video file maximum allowed size is 125 MB (approximately 1 min long HD video). Please make sure to compress or cut videos before uploading.',
  pay: 'Pay',
  feedback_info: 'Info',
  feedback_info_more:
    'This is where you can share your videos and ask questions. If you are having any training issues, please post a video along with the question so that I can see exactly what is happening. There is no limit on amount of videos you can post but they must be under 1 minute long (please keep them as short as possible) and edited to only the important bits!',
  faq: 'FAQs',
  tandc: 'Terms and Conditions',
  feedback_in_progress: 'Feedback (In Progress)',
  privacy_policy: 'Privacy Policy',
  how_it_works: 'How it works',
  supported_extensions: 'supported extansions: jpg, jpeg, png',
  total: 'Total',
  address_line_1: 'Address Line 1',
  address_line_2: 'Address Line 2',
  address_line_3: 'Address Line 3',
  postcode: 'Postcode',
  country: 'Country',
  additional_information: 'Additional Information',
  tricks_count: 'Size',
  last_updated_at: 'Last Updated At',
  feedback_open: 'Feedback (Open)',
  feedback_archive: 'Feedback (Archive)',
  feedback_completed: 'Feedback (Completed)',
  awards_submitted: 'Awards (Submitted)',
  awards_in_progress: 'Awards (In Progress)',
  awards_completed: 'Awards (Completed)',
  please_select_country: 'Please select country',
  field_is_mandatory: 'Field is mandatory',
  continue_to_payment: 'Continue to Payment',
  recommended_tricks: 'Recommended Tricks',
  what_to_do_next: 'What to do next?',
  processing_video_please_come_back_shortly:
    'Video is processing. This will take a few minutes so please check back later.',
  processing_video_error_please_try_again:
    'Video processing error. Please try reupload in few minutes.',
  no_tricks_found: 'No Tricks Found',
  submit: 'Submit',
  information_awards: '',
  information_awards_details:
    'You have earned the {{badge}} Title, the badge has been added to your dogs profile. If you would like to recieve the rosette, please complete the address form below and make payment for delivery.',
  prerequisites: 'Prerequisites',
  please_select_second_dog: 'Select 2nd dog',
  prerequisites_help_text:
    'Below are the tricks that we highly recommend you have completed before starting this trick. They cover the important foundation skills required. Please purchase these first unless you are confident that your dog already has these skills.',
  prerequisites_acknowleadge:
    'I confirm that I have completed (or my dog already knows) the necessary prerequisite tricks',
  not_to_be_sold_individually: 'Not to be sold individually ',
  is_double_dog: 'Double dog trick?',
  is_double_dog_bundle: 'Double dog bundle?',
  join_us_completed_assessment_true: 'My dog has completed previous Trickstars levels',
  join_us_completed_assessment_false: 'I have not completed tricks with Trickstars in the past',
  join_us_completed_assessment: 'Have you completed Trickstars levels before?',
  join_us_additional_info:
    "Good news - your previous levels can transfer over to Trickstars University! Continue signing up to let us know about your dog's previous experience.",
  dog_level_completed_true: 'Has your dog completed Trickstars levels before?',
  dog_level_completed_true_info:
    'Admin will manually review your levels for you - just check the box bellow to notify them that your dog has completed previous Trickstars levels in the past.'
};
