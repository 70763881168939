import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './configs/routes';
import './configs/i18n';
import * as serviceWorker from './serviceWorker';
import './index.less';
import GA4React from 'ga-4-react';

ReactDOM.render(<Routes />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

try {
  if (window.location.hostname !== 'localhost') {
    setTimeout(_ => {
      const ga4react = new GA4React('G-V7YS9QP2MM');
      ga4react.initialize().catch(err => console.error(err));
    }, 3000);
  }
} catch (err) {
  console.error(err);
}
